import styled from 'styled-components';

interface IExportContainerProps {
    isSpinning: boolean;
}

export const ExportContainer = styled.div<IExportContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background: ${props => props.isSpinning ? 'rgba(0 ,0 ,0, 0.2)' : ''};
`;

export const ExportDatePicker = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 24px;
`;

export const ExportButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
`;

interface IExportButtonProps {
    active: boolean;
}

export const ExportButton = styled.div<IExportButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 219px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    background: ${props => props.active ? '#FFA002' : '#BDBDBD'};
    color: ${props => props.active ? '#980303' : '#fff'};
`;

export const ExportTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    color: #7A0202;
    & p:nth-child(1) {
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
    };
    & p:nth-child(2) {
       font-size: 20px;
       font-weight: 400;
       font-style: normal;
       padding-left: 10px;
    };
`;

export const ExportDatePickerWrapper = styled.div`
    margin-left: 40px;
    margin-right: 40px;
`;

export const ExportDateField = styled.input`
    width: 300px;
    height: 36px;
    border-radius: 8px;
    font-size: 18px;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    margin-bottom: 28px;
    padding-left: 10px;
`;

export const ExportSpinnerWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 50%;
`;