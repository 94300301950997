import React from 'react';
import Order from '../../logic/redux/order';
import {
  CardContainer,
  CardContent,
  CardContentRow,
  CardContentRowText,
  CardFooter,
  CardFooterText,
  CardHeader,
  CardHeaderRow,
  CardHeaderText,
  CardSingleContent,
  CardSwipeButton,
} from './style';
import { DateTime } from 'luxon';

interface IOrderCardProps {
  type: 'details' | 'done' | 'details-modal';
  orderData: any;
  style: any;
  onShowDeclineModal: (id: number) => void;
  onStatusChange?: (payload: { id: number; status: number; note: string }) => void;
  onDoneCardClick: (id: number) => void;
  onPrintOrder: (id: number) => void;
}

const OrderCard = (props: IOrderCardProps): JSX.Element => {
  const handleStatusChange = (): void => {
    if (props.onStatusChange) {
      let changeStatusTo = 1;
      if (props.orderData.status === 1) {
        changeStatusTo = 2;
      } else if (props.orderData.status === 2) {
        changeStatusTo = 4;
      }
      props.onStatusChange({ id: props.orderData.id, status: changeStatusTo, note: '' });
    }
  };

  const handleActionButtons = (): void => {
    props.onPrintOrder(props.orderData.id);
  };

  const renderTime = (): string => {
    if (props.type !== 'details-modal') {
      switch (props.orderData.status) {
        case 1:
          return DateTime.fromSeconds(props.orderData.createdAt).toFormat('HH:mm');
        case 2:
          return DateTime.fromSeconds(props.orderData.accepted!.time).toFormat('HH:mm');
        case 3:
          return DateTime.fromSeconds(props.orderData.declined!.time).toFormat('HH:mm');
        case 4:
          return DateTime.fromSeconds(props.orderData.completed!.time).toFormat('HH:mm');
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  const renderOrderItems = (orderItems: Order.model.IOrderItem[]): JSX.Element[] => {
    const renderArr: JSX.Element[] = [];
    for (const item of orderItems) {
      if (item.options === undefined || item.options.length === 0) {
        renderArr.push(
          <CardContentRow type={'general'} key={item.id.toString() + 'order_item_product'}>
            <CardContentRowText>
              {item.quantity !== 1 ? item.quantity + ' x' : ''} {item.name}
            </CardContentRowText>
            <CardContentRowText>{item.price} Ft</CardContentRowText>
          </CardContentRow>,
        );
      } else {
        renderArr.push(
          <React.Fragment key={item.id.toString() + 'order_item_product'}>
            <CardContentRow type={'general'}>
              <CardContentRowText>{item.name}</CardContentRowText>
              <CardContentRowText>{item.price} Ft</CardContentRowText>
            </CardContentRow>
            <CardContentRow type={'list'}>
              <ul>
                {item.options.map((x: Order.model.IOrderItemOption, index: number) => {
                  return (
                    <li key={index.toString() + '_order_item_option'}>
                      {x.quantity !== 1 ? x.quantity + ' x' : ''} {x.name}
                    </li>
                  );
                })}
              </ul>
            </CardContentRow>
          </React.Fragment>,
        );
      }
    }
    return renderArr;
  };

  const renderCardContent = (): JSX.Element => {
    if (props.type === 'details' || props.type === 'details-modal') {
      return (
        <>
          <CardHeader>
            <CardHeaderRow>
              <CardHeaderText>Rendelés #{props.orderData.displayId}</CardHeaderText>
              {props.type === 'details-modal' ? (
                <CardHeaderText>
                  Rendelés egyedi azonosító #{props.orderData.randomId}
                </CardHeaderText>
              ) : null}
              <CardHeaderText>
                {props.type !== 'details-modal'
                  ? props.orderData.status === 1
                    ? 'Beérkezett:'
                    : 'Elfogadva:'
                  : ''}{' '}
                {renderTime()}
              </CardHeaderText>
            </CardHeaderRow>
            <CardHeaderRow>
              <CardHeaderText>Kért időpont: </CardHeaderText>
              <CardHeaderText>
                {props.orderData.foodOrderTime
                  ? DateTime.fromISO(props.orderData.foodOrderTime)
                      .plus({ minute: 5 })
                      .toFormat('HH:mm')
                  : '-'}
              </CardHeaderText>
            </CardHeaderRow>
          </CardHeader>
          <CardContent fixedHeight={props.type === 'details-modal'}>
            {renderOrderItems(props.orderData.items)}
          </CardContent>
          <CardContent fixedHeight={props.type === 'details-modal'}>
            <CardContentRow type={'totalPrice'}>
              <CardContentRowText>Összesen:</CardContentRowText>
              <CardContentRowText>{props.orderData.price} Ft</CardContentRowText>
            </CardContentRow>
          </CardContent>
          {props.type !== 'details-modal' ? (
            <CardFooter>
              <CardContentRow type={'actionButton'}>
                <CardFooterText>
                  <CardSwipeButton
                    minSwipeWidth={0.8}
                    delta={10}
                    minSwipeVelocity={0.6}
                    mainText={props.orderData.status === 1 ? 'Elfogadás' : 'Elkészült'}
                    overlayText={''}
                    onSwipeDone={() => handleStatusChange()}
                  />
                </CardFooterText>
                {/*props.orderData.status === 2 ? (
                  <CardFooterText onClick={handleActionButtons}>Nyomtatás</CardFooterText>
                ) : null*/}
                <CardFooterText onClick={() => props.onShowDeclineModal(props.orderData.id)}>
                  Elutasítás
                </CardFooterText>
              </CardContentRow>
            </CardFooter>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <CardSingleContent
            isError={props.orderData.status === 3}
            onClick={() => props.onDoneCardClick(props.orderData.id)}
          >
            <CardHeaderText>Rendelés # {props.orderData.displayId}</CardHeaderText>
            <CardHeaderText>
              {props.orderData.status === 4 ? 'Elkészült:' : 'Elutasítva:'} {renderTime()}
            </CardHeaderText>
          </CardSingleContent>
        </>
      );
    }
  };

  return (
    <div style={props.style}>
      <CardContainer type={props.type}>{renderCardContent()}</CardContainer>
    </div>
  );
};

export default OrderCard;
