/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import Common from './common';
import Auth from './auth';
import Order from './order';
import Export from './export';
import Complaint from './complaint';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (history: any) =>
    combineReducers({
        common: Common.reducer,
        auth: Auth.reducer,
        order: Order.reducer,
        router: connectRouter(history),
        export: Export.reducer,
        complaint: Complaint.reducer,
    });
