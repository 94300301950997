/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import {ExportQuery } from '../../graphql/types/types';
import { doGetLastExport, doExport } from './export-thunk-actions';
import {ExportErrorModal} from "./export-models";

export const actionTypes = {
    SET_LAST_EXPORT: 'Export/SetLastExport',
    EMPTY_EXPORT: 'Export/Empty',
    SET_FROM: 'Export/SetFrom',
    SET_TO: 'Export/SetTo',
    SET_ERROR_MODAL: 'Export/SetErrorModal',
};

export const actions = {
    emptyExport: () => action(actionTypes.EMPTY_EXPORT),
    setLastExport: (payload: ExportQuery) => action(actionTypes.SET_LAST_EXPORT, payload),
    setFrom: (payload: number) => action(actionTypes.SET_FROM, payload),
    setTo: (payload: number) => action(actionTypes.SET_TO, payload),
    setErrorModal: (payload: ExportErrorModal) => action(actionTypes.SET_ERROR_MODAL, payload),
    doGetLastExport,
    doExport,
};
