import {ConnectedRouter} from 'connected-react-router';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import Auth from './logic/redux/auth';
import { history, store } from './logic/redux/store';
import Navigation from './navigation/navigation';
import './app.css';
import moment from 'moment';
import 'moment/locale/hu';
import client from "./logic/graphql/graphql-apollo";
import Common from './logic/redux/common';

moment.locale('hu');

export let checkIsOnlineInterval = setInterval(async () => {
    const action = Common.actions.doCheckOnline()
    await action(store.dispatch, store.getState);
}, 2000);

const App = (): JSX.Element => {

    React.useEffect(() => {
        store.dispatch<any>(Auth.actions.doHandleApplicationStarted());
    }, []);

    return (
        <ReduxProvider store={store}>
            <ApolloProvider client={client}>
                <ConnectedRouter history={history}>
                    <Navigation/>
                </ConnectedRouter>
            </ApolloProvider>
        </ReduxProvider>
    );
};

export default App;
