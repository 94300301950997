/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import produce from 'immer';
import Common from '.';

const initializeState: Common.model.CommonReducer = {
    loading: 0,
    online: false,
};

export default (
    state = initializeState,
    action: { type: string; payload: Common.model.CommonActionsPayload },
) =>
    produce(state, (draft) => {
        switch (action.type) {
            case Common.actionTypes.INCREASE_LOADING:
                draft.loading++;
                return draft;
            case Common.actionTypes.DECREASE_LOADING:
                draft.loading--;
                return draft;
            case Common.actionTypes.SET_ONLINE:
                draft.online = action.payload as boolean;
                return draft;
            default:
        }
    });
