import React from 'react'
import { useSwipeable } from 'react-swipeable';
import Arrow from './arrow.png';
import { SwipeButtonContainer, SwipeButtonOverlay, SwipeButtonOverlayWrapper, SwipeButtonCaretWrapper, SwipeButtonOverlayText } from './style';

const findLeft = (element: any) => {
  var rec = element.getBoundingClientRect();
  return rec.left + window.scrollX;
}

interface ISwipeButtonProps {
    mainText: string;
    overlayText: string;
    onSwipeDone: () => void;
    delta: number;
    minSwipeWidth: number;
    minSwipeVelocity: number;
}

function SwipeButton(props: ISwipeButtonProps) {

    const [overlayWidth, setOverlayWidth] = React.useState<number>(40);
    const [swipeComplete, setSwipeComplete] = React.useState<boolean>(false);
    const buttonRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (overlayWidth < 40) {
            setSwipeComplete(false);
            setOverlayWidth(40);
        }
    }, [overlayWidth]);

    const handlers = useSwipeable({
        onSwipedRight: (data: any) => {
            if (swipeComplete) {
                return;
            }
            let butWidth = 40;
            if (buttonRef.current) {
                butWidth = buttonRef.current.offsetWidth;
            }
            if (data.velocity > props.minSwipeVelocity) {
                setOverlayWidth(butWidth);
                setSwipeComplete(true);
                setTimeout(() => props.onSwipeDone(), 100);
            }
            else {
                const offsetLeft = findLeft(buttonRef.current);
                const startPos = Math.abs(data.initial[0] - offsetLeft);
                if (startPos <= 100 && (data.event.type === "touchend" ? data.event.changedTouches[0].clientX - offsetLeft : data.event.offsetX) > props.minSwipeWidth * butWidth) {
                    setOverlayWidth(butWidth);
                    setSwipeComplete(true);
                    setTimeout(() => props.onSwipeDone(), 100);
                } else {
                    setOverlayWidth(40);
                }
            }
        },
        onSwiping: (data: any) => {
            if (swipeComplete) {
                return;
            }
            const offsetLeft = findLeft(buttonRef.current);
            const startPos = Math.abs(data.initial[0] - offsetLeft);
            if (startPos <= 100) {
                if (data.event.type && data.event.type === "touchmove")
                setOverlayWidth(data.event.touches[0].clientX - offsetLeft);
                else
                setOverlayWidth(data.event.offsetX);
            }
        },
        delta: props.delta,
        trackMouse: true,
        preventDefaultTouchmoveEvent: true
    });

    return (
        <SwipeButtonContainer className={`swipezor-but`} {...handlers} ref={(t) => {
            handlers.ref(t);
            buttonRef.current = t;
        }}>
        <SwipeButtonOverlay className={`swipezor-overlay`} style={{ width: overlayWidth }}>
            <SwipeButtonOverlayWrapper className="swipezor-overlay-wrapper">
                <SwipeButtonCaretWrapper className={`swipezor-caret-wrapper`}>
                    <img src={Arrow} alt="caret" style={{ maxWidth: '100%' }} />
                </SwipeButtonCaretWrapper>
                <SwipeButtonOverlayText className="swipezor-overlay-txt">
                    {props.overlayText}
                </SwipeButtonOverlayText>
            </SwipeButtonOverlayWrapper>
        </SwipeButtonOverlay>
            {props.mainText}
        </SwipeButtonContainer>
    )
}

export default SwipeButton;