import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerModal = styled.div`
  width: 150px;
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  &:before {
    content: '';
    color: white;
    height: 30px;
    width: 30px;
    background: transparent;
    border-radius: 50%;
    border: 5px solid blue;
    border-color: #00786e #ffffff #00786e #ffffff;
    animation: ${rotateAnimation} 0.55s infinite ease-in-out;
  }
`;

export default Spinner;
