import * as React from 'react';
import Menu from '../components/navigation/menu';

const Navigation = (): JSX.Element => {
    return (
        <>
            <Menu />
        </>
    );
};

export default Navigation;
