import styled from 'styled-components';
import SwipeButton from '../swipe-button/swipe-button';

interface IOrderCardProps {
  type: 'details' | 'done' | 'details-modal';
}

export const CardContainer = styled.div<IOrderCardProps>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.type === 'details-modal' ? '0' : '10px')};
  margin-right: ${(props) => (props.type === 'details-modal' ? '0' : '10px')};
  box-shadow: ${(props) =>
    props.type === 'details-modal' ? '' : '0px 2px 4px rgba(0, 0, 0, 0.25)'};
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface ICardSingleContent {
  isError: boolean;
}

export const CardSingleContent = styled.div<ICardSingleContent>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => (props.isError ? '#7E0000' : '#FFA002')};
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffa002;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
`;

export const CardHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardHeaderText = styled.div`
  font-size: 20px;
  color: #fff;
  font-weight: 500;
`;

interface ICardContentProps {
  fixedHeight: boolean;
}

export const CardContent = styled.div<ICardContentProps>`
  flex: 1 1 auto;
  max-height: ${(props) => (props.fixedHeight ? '300px' : '')};
  overflow-y: ${(props) => (props.fixedHeight ? 'scroll' : '')};
`;

interface ICardContentRowProps {
  type: 'general' | 'actionButton' | 'totalPrice' | 'list';
}

export const CardContentRow = styled.div<ICardContentRowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.type !== 'list' ? '30px' : '')};
  background: ${(props) =>
    props.type === 'general'
      ? 'rgba(247, 173, 51, 0.6)'
      : props.type === 'totalPrice'
      ? '#FFBC4E'
      : '#fff'};
  & ul {
    font-size: 16px;
    font-weight: 300;
    color: #7a0202;
    margin-top: 0px;
    margin-bottom: 0px;
    li {
      margin-top: 0px;
      margin-bottom: 0px;
      height: 30px;
    }
  }
`;

export const CardContentRowText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #7a0202;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex: 0 1 40px;
  flex-direction: column;
  justify-content: center;
`;

export const CardSwipeButton = styled(SwipeButton)`
  & {
    color: #009688;
  }
`;

export const CardFooterText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #009688;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
`;
