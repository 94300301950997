import {Dispatch} from 'redux';
import Common from '../common';
import {IStore} from '../IStore';
import client from "../../graphql/graphql-apollo";
import { gql } from '@apollo/client';
import Complaint from '.';
import {searchOrders} from "../../graphql/generated-operations/queries/searchOrders";

export const doSearch = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(Common.actions.increaseLoading());
        const displayId = Complaint.select.selectDisplayId(getState());
        const randomId = Complaint.select.selectRandomId(getState());
        setTimeout(async () => {
            if (!randomId && !displayId) {
                dispatch(Common.actions.decreaseLoading());
                dispatch(Complaint.actions.setSearchModal({
                    show: true,
                    id: 0,
                    error: true,
                    errorText: 'A rendelés egyedi azonosítója és a rendelés száma hiányzik. Az egyiket töltse ki megfelelően!',
                }));
                return;
            }
            if (randomId && randomId.toString().length !== 6) {
                dispatch(Common.actions.decreaseLoading());
                dispatch(Complaint.actions.setSearchModal({
                    show: true,
                    id: 0,
                    error: true,
                    errorText: 'A rendelés azonosítónak 6 jegyű számnak kell lennie!',
                }));
                return;
            }

            let variables;

            if (randomId) {
                variables = {
                    randomId
                }
            }

            if (displayId) {
                variables = {
                    displayId
                }
            }

            if (randomId && displayId) {
                variables = {
                    displayId, randomId
                }
            }

            try {
                const result: any = await client.query({
                    query: gql`${searchOrders}`,
                    fetchPolicy: "no-cache",
                    variables: {"input": variables},
                });

                if (!result.error && result.data) {
                    if (result.data.searchOrders.length) {
                        dispatch(Common.actions.decreaseLoading());
                        dispatch(Complaint.actions.setFoundOrders(result.data.searchOrders));
                    } else {
                        dispatch(Common.actions.decreaseLoading());
                        dispatch(Complaint.actions.setSearchModal({
                            show: true,
                            id: 0,
                            error: true,
                            errorText: 'Nem találjuk a keresett rendelés(eke)t. Biztos, hogy helyesek a megadott adatok?',
                        }))
                    }
                }

            } catch (e) {
                dispatch(Common.actions.decreaseLoading());
                dispatch(Complaint.actions.setSearchModal({
                    show: true,
                    id: 0,
                    error: true,
                    errorText: 'A szerver nem adott vissza megfelelő adatokat!',
                }))
            }
        }, 2000)
    };
}