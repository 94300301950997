import React from 'react';
import { DiagnosticContainer } from './style';

const Diagnostic = (): JSX.Element => {

    return (
        <DiagnosticContainer>
            Diagnostic content
        </DiagnosticContainer>
    );
};

export default Diagnostic;
