import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const GRAPHQL_API_URL =
  process.env.REACT_APP_API !== undefined
    ? process.env.REACT_APP_API + '/query'
    : 'http://' + window.location.hostname + ':8010/query';
const WS_API_URL =
  process.env.REACT_APP_WS !== undefined
    ? process.env.REACT_APP_WS + '/query'
    : 'ws://' + window.location.hostname + ':8010/query';
//const GRAPHQL_API_URL = 'http://localhost:8010/query';
//const WS_API_URL = 'ws://localhost:8010/query';
const token = sessionStorage.getItem('token');

const wsLink = new WebSocketLink({
  uri: WS_API_URL,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        'Current-User-Token': token ? token : '',
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('token');
  return {
    headers: {
      ...headers,
      'Current-User-Token': token ? token : '',
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default client;
