import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/utils/private-route';
import { Screens } from '../screens';
import { Paths } from './paths';

export const Routes = (): JSX.Element => (
  <Switch>
    <Route exact={true} path={Paths.login} component={Screens.Login} />
    <PrivateRoute exact={true} path={Paths.dashboard} component={Screens.Dashboard} />
    <PrivateRoute exact={true} path={Paths.exportScreen} component={Screens.ExportScreen} />
    <PrivateRoute exact={true} path={Paths.diagnostic} component={Screens.Diagnostic} />
    <PrivateRoute exact={true} path={Paths.menuEditor} component={Screens.MenuEditor} />
    <PrivateRoute exact={true} path={Paths.complaint} component={Screens.ComplaintHandler} />
  </Switch>
);
