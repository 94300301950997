/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import produce from 'immer';
import Auth from '.';
import {AuthErrorPayload} from './auth-models';

const initializeState: Auth.model.AuthReducer = {
    loggedIn: false,
    loginRedirectPath: "",
    loginErrors: {},
    token: '',
    displayName: '',
};

export default (
    state = initializeState,
    action: { type: string; payload: Auth.model.AuthActionsPayload },
) =>
    produce(state, (draft) => {
        switch (action.type) {
            case Auth.actionTypes.SET_USER_LOGGED_OUT:
                draft.loggedIn = false;
                return draft;
            case Auth.actionTypes.SET_USER_LOGGED_IN:
                draft.loggedIn = true;
                return draft;
            case Auth.actionTypes.SET_LOGIN_REDIRECT_PATH:
                draft.loginRedirectPath = action.payload as string;
                return draft;
            case Auth.actionTypes.SET_LOGIN_ERROR:
                // eslint-disable-next-line no-case-declarations
                const {key, value} = action.payload as AuthErrorPayload;
                draft.loginErrors[key] = value;
                return draft;
            case Auth.actionTypes.SET_LOGIN_TOKEN:
                draft.token = action.payload as string;
                return draft;
            case Auth.actionTypes.SET_LOGIN_DISPLAY_NAME:
                draft.displayName = action.payload as string;
                return draft;
            default:
        }
    });
