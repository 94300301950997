/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { doGetOrders, doStatusChange, doGetOrderDetail, doPrintOrder } from './order-thunk-actions';
import { OrderModel } from './order-models';
import { Order } from '../../graphql/types/types';

export const actionTypes = {
    SET_ORDERS: 'Orders/SetOrders',
    EMPTY_ORDERS: 'Orders/Empty',
    SET_IS_ALL: 'Orders/SetIsAll',
    REMOVE_FROM_ORDERS: 'Orders/RemoveFromOrders',
    SET_ORDER_DETAILS: 'Orders/setOrderDetails',
    INIT_ORDER_DETAILS: 'Orders/initOrderDetails',
};

export const actions = {
    doGetOrders,
    setOrders: (orders: OrderModel) => action(actionTypes.SET_ORDERS, orders),
    emptyOrders: () => action(actionTypes.EMPTY_ORDERS),
    doStatusChange,
    setOrderDetails: (payload: Order) => action(actionTypes.SET_ORDER_DETAILS, payload),
    doGetOrderDetail,
    doPrintOrder,
    initOrderDetails: () => action(actionTypes.INIT_ORDER_DETAILS),
};
