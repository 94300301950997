/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {action} from 'typesafe-actions';
import {doCheckOnline} from "./common-thunk-actions";

export const actionTypes = {
    INCREASE_LOADING: 'Common/IncreaseLoading',
    DECREASE_LOADING: 'Common/DecreaseLoading',
    SET_ONLINE: 'Common/SetOnline'
};

export const actions = {
    increaseLoading: () => action(actionTypes.INCREASE_LOADING),
    decreaseLoading: () => action(actionTypes.DECREASE_LOADING),
    setOnline: (payload: boolean) => action(actionTypes.SET_ONLINE, payload),
    doCheckOnline,
};
