import styled from 'styled-components';

interface IComplaintContainerProps {
    isSpinning: boolean;
}

export const ComplaintContainer = styled.div<IComplaintContainerProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: ${props => props.isSpinning ? 'rgba(0 ,0 ,0, 0.2)' : ''};
`;

export const ComplaintPart = styled.div`
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ComplaintFoundWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

export const ComplaintInputTitle = styled.title`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
`;

export const ComplaintInputTitleSpan = styled.span`
    margin-right: 10px;
    justify-content: center;
    font-size: 18px;
    margin-top: 5px;
`;

export const ComplaintInput = styled.input`
    width: 150px;
    height: 36px;
    border-radius: 8px;
    font-size: 18px;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    margin-bottom: 28px;
    padding-left: 10px;
`;

export const ComplaintSpinner = styled.div`
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 45.5%;
`;

interface IComplaintButtonProps {
    active: boolean;
}

export const ComplaintButton = styled.div<IComplaintButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 219px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    background: ${props => props.active ? '#FFA002' : '#BDBDBD'};
    color: ${props => props.active ? '#980303' : '#fff'};
`;

export const ResetButton = styled.div`
    position: relative;
    margin-left: -80px;
    float: right;
    z-index: 3;
    width: 40px;
    height: 40px;
`;