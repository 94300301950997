import React from 'react';
import { Routes } from '../../navigation/routes';
import {
  NavContainer,
  NavHeader,
  NavButton,
  NavTitle,
  NavMenu,
  ContentContainer,
  NavMenuItem,
  NavTitleContainer,
  NavStatus,
  NavMenuUserContainer,
  NavMenuUserImage,
  NavMenuUserName,
  NavMenuUserSection,
  NavMenuUserTitle,
  EmptyNavButton,
} from './style';
import {
  ArrowLeftOutlined,
  UserOutlined,
  SendOutlined,
  // DeleteFilled,
  DownloadOutlined,
  WarningFilled,
  LogoutOutlined,
} from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Paths } from '../../navigation/paths';
import { IStore } from '../../logic/redux/IStore';
import { selectIsOnline } from '../../logic/redux/common/common-selector';
import Auth from '../../logic/redux/auth';
import Order from '../../logic/redux/order';
import Export from '../../logic/redux/export';

const Menu = (): JSX.Element => {
  const isOnline = useSelector(selectIsOnline, shallowEqual);
  const isLoggedIn = useSelector(Auth.select.selectLoggedIn, shallowEqual);
  const [menuOpen, setMenuOpen] = React.useState<'open' | 'close' | null>(null);
  const displayName = useSelector((store: IStore) => store.auth.displayName);
  const dispatch = useDispatch();

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (ref.current && !ref.current?.contains(event.target)) {
        setMenuOpen('close');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const onLogOutClick = (): void => {
    if (isLoggedIn) {
      sessionStorage.clear();
      dispatch(Order.actions.emptyOrders());
      dispatch(Export.actions.emptyExport());
      dispatch(Auth.actions.setUserLoggedOut());
      window.location.href = '/login';
    }
  };

  const onMenuBackButtonPress = (): void => {
    setMenuOpen('close');
  };

  const onMenuItemClick = (navigateTo: string): void => {
    dispatch(push(navigateTo));
    setMenuOpen('close');
  };

  const renderNavbarText = (): string => {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case Paths.login:
        return 'Bejelentkezés';
      case Paths.dashboard:
        return 'Aktív rendelések';
      case Paths.exportScreen:
        return 'Export';
      case Paths.menuEditor:
        return 'Menü szerkesztő';
      default:
        return '';
    }
  };

  const isMenuActive = (path: string): boolean => {
    const currentPath = window.location.pathname;
    if (currentPath === path) {
      return true;
    }
    return false;
  };

  return (
    <>
      <NavContainer>
        <NavHeader>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}
          >
            {isLoggedIn ? (
              <NavButton onClick={() => setMenuOpen('open')}>
                <svg
                  style={{ height: '22.5px', width: '22.5px', fill: 'currentColor' }}
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </NavButton>
            ) : (
              <EmptyNavButton />
            )}
            <NavTitleContainer>
              <NavTitle>{renderNavbarText()}</NavTitle>
              <NavStatus>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: isOnline ? 'green' : 'red',
                    marginRight: '10px',
                    marginTop: '15px',
                    borderRadius: '50%',
                  }}
                />
                Hálózat: {isOnline ? 'elérhető' : 'nem elérhető'}
              </NavStatus>
            </NavTitleContainer>
          </div>
        </NavHeader>
        <NavMenu visible={menuOpen} ref={ref}>
          <ArrowLeftOutlined
            onClick={onMenuBackButtonPress}
            style={{ color: '#fff', marginLeft: '15px', fontSize: '25px', marginTop: '20px' }}
          />
          <NavMenuUserSection>
            <NavMenuUserTitle>Felhasználó</NavMenuUserTitle>
            <NavMenuUserContainer>
              <NavMenuUserImage>
                <UserOutlined style={{ color: '#7A0202', fontSize: '20px' }} />
              </NavMenuUserImage>
              <NavMenuUserName>{displayName}</NavMenuUserName>
            </NavMenuUserContainer>
          </NavMenuUserSection>
          <hr style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
          {isLoggedIn ? (
            <NavMenuItem
              active={isMenuActive(Paths.dashboard)}
              onClick={() => onMenuItemClick(Paths.dashboard)}
            >
              <div>
                <DownloadOutlined style={{ fontSize: '18px' }} />
              </div>
              <p>Aktív rendelések</p>
            </NavMenuItem>
          ) : null}
          {/*<NavMenuItem active={isMenuActive(Paths.menuEditor)} onClick={() => onMenuItemClick(Paths.menuEditor)}>
                        <div>
                            <SendOutlined style={{fontSize: '18px'}} />
                        </div>
                        <p>
                            Menü szerkesztése
                        </p>
                    </NavMenuItem>
                    <NavMenuItem active={isMenuActive(Paths.diagnostic)} onClick={() => onMenuItemClick(Paths.diagnostic)}>
                        <div>
                            <DeleteFilled style={{fontSize: '18px'}} />
                        </div>
                        <p>
                            Diagnosztika
                        </p>
                    </NavMenuItem>*/}
          {/*isLoggedIn ?
                        <NavMenuItem active={isMenuActive(Paths.complaint)} onClick={() => onMenuItemClick(Paths.complaint)}>
                            <div>
                                <WarningFilled style={{fontSize: '18px'}} />
                            </div>
                            <p>
                                Panaszkezelés
                            </p>
                </NavMenuItem> : null */}
          {/*isLoggedIn ?
                    <NavMenuItem active={isMenuActive(Paths.exportScreen)} onClick={() => onMenuItemClick(Paths.exportScreen)}>
                        <div>
                            <SendOutlined style={{fontSize: '18px'}} />
                        </div>
                        <p>
                            Export
                        </p>
            </NavMenuItem> : null*/}
          {isLoggedIn ? (
            <NavMenuItem active={false} onClick={onLogOutClick}>
              <div>
                <LogoutOutlined style={{ fontSize: '18px' }} />
              </div>
              <p>Kijelentkezés</p>
            </NavMenuItem>
          ) : null}
        </NavMenu>
        <ContentContainer>
          <Routes />
        </ContentContainer>
      </NavContainer>
    </>
  );
};

export default Menu;
