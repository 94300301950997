import React from 'react';
import { ModalContainer, ModalInnerContainer, ModalHeader, ModalContent, ModalContentInner, ModalFooter, ModalFooterButton } from './style';

interface IModalProps {
    headerText: string;
    cancelButtonText?: string;
    onCancelButton?: (e: any) => void;
    okButtonText?: string;
    onOkButton?: () => void;
    mainContent: JSX.Element | null;
    showModal: boolean;
}

const Modal = (props: IModalProps): JSX.Element => {

    const modalReference = React.useRef<HTMLHeadingElement>(null);

    if (window) {
        window.addEventListener('mouseup', (e: MouseEvent) => {
            if (modalReference.current) {
                const target = (e.target as HTMLHeadingElement)
                if (modalReference.current !== target && !modalReference.current.contains(target)) {
                    if (props.onCancelButton) {
                        props.onCancelButton(e);
                    }
                }
            }
        });
    }

    const onOkButtonClick = (): void => {
        if (props.onOkButton) {
            props.onOkButton();
        }
    }

    const onCancelButtonClick = (e: any): void => {
        if (props.onCancelButton) {
            props.onCancelButton(e);
        }
    }

    return (
        <ModalContainer showModal={props.showModal}>
            <ModalInnerContainer ref={modalReference}>
                <ModalHeader>
                    <p>
                        {props.headerText}
                    </p>
                </ModalHeader>
                <ModalContent>
                    <ModalContentInner>
                        {props.mainContent}
                    </ModalContentInner>
                </ModalContent>
                <ModalFooter>
                    <ModalFooterButton onClick={(e) => onCancelButtonClick(e)}>
                        {props.cancelButtonText}
                    </ModalFooterButton>
                    <ModalFooterButton onClick={onOkButtonClick}>
                        {props.okButtonText}
                    </ModalFooterButton>
                </ModalFooter>
            </ModalInnerContainer>
        </ModalContainer>
    );
};

export default Modal;