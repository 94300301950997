export const orders = `subscription orders{
    orders{
        incoming{
            id
            displayId
            randomId
            status
            price
            createdAt
            items{
                name
                price
                quantity
                id
                options{
                    name
                    price
                    quantity
                    id
                }
            }
            accepted{
                time
                userName
                note
            }
            declined{
                time
                userName
                note
            }
            completed{
                time
                userName
                note
            }
        }
        accepted{
            id
            displayId
            randomId
            status
            price
            createdAt
            items{
                name
                price
                quantity
                id
                options{
                    name
                    price
                    quantity
                    id
                }
            }
            accepted{
                time
                userName
                note
            }
            declined{
                time
                userName
                note
            }
            completed{
                time
                userName
                note
            }
        }
        done{
            id
            displayId
            randomId
            status
            price
            createdAt
            items{
                name
                price
                quantity
                id
                options{
                    name
                    price
                    quantity
                    id
                }
            }
            accepted{
                time
                userName
                note
            }
            declined{
                time
                userName
                note
            }
            completed{
                time
                userName
                note
            }
        }
    }
}`;
