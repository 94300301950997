import {Dispatch} from 'redux';
import Common from '../common';
import {IStore} from '../IStore';
import client from "../../graphql/graphql-apollo";
import Export from '.';
import { lastExport } from '../../graphql/generated-operations/queries/lastExport';
import { exportOrders } from '../../graphql/generated-operations/mutations/exportOrders';
import { gql } from '@apollo/client';

export const doGetLastExport = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(Common.actions.increaseLoading());
        try {
            const result = await client.query({
                query: gql`${lastExport}`,
                fetchPolicy: "no-cache",
            });
            console.log(result);
            if (!result.error) {
                dispatch(Export.actions.setLastExport(result.data.lastExport));
            }
        } catch (err) {
            // TODO: Error handling
        }
        dispatch(Common.actions.decreaseLoading());
    };
};

export const doExport = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(Common.actions.increaseLoading());
        const from = getState().export.From;
        const to = getState().export.To;
        try {
            const result: any = await client.mutate({
                mutation: gql`${exportOrders}`,
                variables: {"input": {
                    from,to
                }},
            });

            if (!result.error) {
                const action = doGetLastExport();
                await action(dispatch, getState);
            }

        } catch (e) {
            dispatch(Export.actions.setErrorModal({
                show: true,
                text: 'Nem érkezett megfelelő válasz a szervertől.',
            }));
        }
        dispatch(Common.actions.decreaseLoading());
    };
};
