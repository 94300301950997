/* eslint-disable @typescript-eslint/explicit-function-return-type */
import produce from 'immer';
import Order from '.';
import { OrderModel } from './order-models';
import { Order as OrderDetail } from '../../graphql/types/types';

const initializeState: Order.model.OrderReducer = {
  orderNumbers: {
    income: 0,
    accepted: 0,
    done: 0,
  },
  orders: {
    incoming: [],
    accepted: [],
    done: [],
  },
  orderDetail: {
    id: '',
    randomId: 0,
    displayId: '0',
    status: 1,
    price: 0,
    createdAt: 0,
    items: [],
    accepted: undefined,
    declined: undefined,
    completed: undefined,
  },
};

export default (
  state = initializeState,
  action: { type: string; payload: Order.model.OrderActionsPayload },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case Order.actionTypes.SET_ORDERS:
        console.log('Set orders');
        console.log(action.payload);
        draft.orders = action.payload as Order.model.OrderModel;
        return draft;
      case Order.actionTypes.EMPTY_ORDERS:
        draft.orders = {
          incoming: [],
          accepted: [],
          done: [],
        };
        return draft;
      case Order.actionTypes.SET_ORDER_DETAILS:
        draft.orderDetail = action.payload as OrderDetail;
        return draft;
      case Order.actionTypes.INIT_ORDER_DETAILS:
        draft.orderDetail = initializeState.orderDetail;
        return draft;
      default:
        return draft;
    }
  });
