/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import {
  doHandleApplicationStarted,
  doLogin,
} from './auth-thunk-actions';

export const actionTypes = {
  DO_LOGIN: 'Auth/DoLogin',
  DO_HANDLE_ALREADY_LOGGED_IN: 'Auth/DoHandleAlreadyLoggedIn',
  SET_USER_LOGGED_IN: 'Auth/SetUserLoggedIn',
  SET_LOGIN_REDIRECT_PATH: 'Auth/SetLoginRedirectPath',
  SET_LOGIN_ERROR: 'Auth/SetLoginError',
  SET_LOGIN_TOKEN: 'Auth/setLoginToken',
  SET_LOGIN_DISPLAY_NAME: 'Auth/setLoginDisplayName',
  SET_USER_LOGGED_OUT: 'Auth/SetUserLoggedOut',
};

export const actions = {
  setUserLoggedIn: () => action(actionTypes.SET_USER_LOGGED_IN),
  setLoginRedirectPath: (path?: string) => action(actionTypes.SET_LOGIN_REDIRECT_PATH, path),
  setLoginError: (key: string, value: string | undefined) =>
    action(actionTypes.SET_LOGIN_ERROR, { key, value }),
  setLoginToken: (payload: string) => action(actionTypes.SET_LOGIN_TOKEN, payload),
  setLoginDisplayName: (payload: string) => action(actionTypes.SET_LOGIN_DISPLAY_NAME, payload),
  setUserLoggedOut: () => action(actionTypes.SET_USER_LOGGED_OUT),
  doLogin,
  doHandleApplicationStarted,
};
