import Dashboard from './dashboard/dashboard';
import Login from './login/login';
import ExportScreen from './exportScreen/exportScreen';
import Diagnostic from './diagnostic/diagnostic';
import MenuEditor from './menuEditor/menuEditor';
import ComplaintHandler from "./complaint/complaint";

export const Screens = {
  Dashboard,
  Login,
  ExportScreen,
  Diagnostic,
  MenuEditor,
  ComplaintHandler,
};
