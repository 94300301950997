import React from 'react';
import { ExportContainer, ExportDatePicker, ExportButtonContainer, ExportButton, ExportTextContainer, ExportDatePickerWrapper, ExportDateField, ExportSpinnerWrapper } from './style';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { DateTime } from 'luxon';
// import Common from '../../logic/redux/common';
import Export from '../../logic/redux/export';
import Modal from '../../components/modal/modal';
import { DeclineModalContainer, DeclineModalMainText, } from '../dashboard/style';
import Spinner, { SpinnerModal } from '../../components/loading-indicators/spinner';

let firstLoad = true;

const ExportScreen = (): JSX.Element => {

    // const loading = useSelector(Common.select.selectLoading);
    const lastExport = useSelector(Export.select.selectExport, shallowEqual);
    const to = useSelector(Export.select.selectTo, shallowEqual);
    const from = useSelector(Export.select.selectFrom, shallowEqual);
    const errorModal = useSelector(Export.select.selectExportErrorModal, shallowEqual);
    const [isSpinning, setIsSpinning] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
       if (firstLoad) {
           const now = DateTime.fromJSDate(new Date()).toSeconds();
           const from = DateTime.fromJSDate(new Date()).minus({days: 0}).startOf('day').toSeconds();
           dispatch(Export.actions.setTo(now));
           dispatch(Export.actions.setFrom(from));
           dispatch(Export.actions.doGetLastExport());
           firstLoad = false;
       }
       return () => {
           firstLoad = true;
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onExportButtonClick = (): void => {
        console.log(from, to);
        if (!to || !from) {
            dispatch(Export.actions.setErrorModal({
                show: true,
                text: 'Az exportálás elkezdéséhez valamelyik dátum hiányik vagy nem helyesen töltötte ki!',
            }));
            return
        }

        if (from >= to) {
            dispatch(Export.actions.setErrorModal({
                show: true,
                text: 'A rendelések exportálásához kért dátumok nem helyesek: az első időpont nem lehet nagyobb a másodiknál, vagy egyenlő vele!',
            }));
            return
        }

        dispatch(Export.actions.doExport());
        setIsSpinning(true);
        setTimeout(() => {
            setIsSpinning(false);
        }, 2000);
    }

    const onFromChange = (e: any): void => {
        if (e.target.value !== '') {
            const from = e.target.value.replace("T", " ");
            const sec = DateTime.fromFormat(from, 'yyyy-MM-dd HH:mm').toSeconds();
            dispatch(Export.actions.setFrom(sec));
        }
    }

    const onToChange = (e: any): void => {
        if (e.target.value !== '') {
            const to = e.target.value.replace("T", " ");
            const sec = DateTime.fromFormat(to, 'yyyy-MM-dd HH:mm').toSeconds();
            dispatch(Export.actions.setTo(sec));
        }
    }

    const onErrorModalOK = (): void => {
        dispatch(Export.actions.setErrorModal({
            show: false,
            text: '',
        }));
    }

    const renderErrorModal = (): JSX.Element => {
        return (
            <DeclineModalContainer>
                <DeclineModalMainText>
                    {errorModal.text}
                </DeclineModalMainText>
            </DeclineModalContainer>
        )
    }

    return (
        <ExportContainer isSpinning={isSpinning}>
            {isSpinning ? 
                <ExportSpinnerWrapper>
                    <SpinnerModal>
                        <Spinner />
                    </SpinnerModal>
                </ExportSpinnerWrapper>
            : null}
            <ExportDatePicker>
                <ExportDatePickerWrapper>
                    <ExportDateField 
                        type="datetime-local"
                        value={DateTime.fromSeconds(from).toFormat("yyyy-MM-dd HH:mm").replace(" ", "T")}
                        onChange={onFromChange}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    />
                </ExportDatePickerWrapper>
                <ExportDatePickerWrapper>
                    <ExportDateField 
                        type="datetime-local"
                        value={DateTime.fromSeconds(to).toFormat("yyyy-MM-dd HH:mm").replace(" ", "T")}
                        onChange={onToChange}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                    />
                </ExportDatePickerWrapper>
            </ExportDatePicker>
            <ExportButtonContainer>
                <ExportButton active={to > from} onClick={onExportButtonClick}>
                    időszak exportálása
                </ExportButton>
            </ExportButtonContainer>
            <ExportTextContainer>
                <p>
                    Utolsó exportálás időpontja:
                </p>
                <p>
                    {lastExport ? DateTime.fromSeconds(lastExport.lastSuccess).toFormat('yyyy LLL dd HH:mm') : 'Még nem sikerült exportálni'}
                </p>
            </ExportTextContainer>
            <ExportTextContainer>
                <p>
                    Sikeresen kiküldve:
                </p>
                <p>
                    {lastExport ? lastExport.sentTo.join(', ') : ''}
                </p>
            </ExportTextContainer>
            <Modal
                showModal={errorModal.show}
                onOkButton={onErrorModalOK}
                onCancelButton={onErrorModalOK}
                okButtonText={'OK'}
                headerText={'Hiba exportálás közben'}
                mainContent={renderErrorModal()} />
        </ExportContainer>
    );
};

export default ExportScreen;
