/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import Auth from '.';
import { IStore } from './../IStore';
import { callLogin } from '../../api/api';

export const doLogin = (email: string, password: string) => {
  return async (dispatch: Dispatch, getState: () => IStore) => {
    try {
      const dataToSend = {
        email,
        password
      };
      const response = await callLogin(dataToSend);
      if (response && response.status === 200) {
        await sessionStorage.setItem('token', response.data.token);
        dispatch(Auth.actions.setLoginError('login', undefined));
        dispatch(Auth.actions.setUserLoggedIn());
        dispatch(Auth.actions.setLoginToken(response.data.token));
        dispatch(Auth.actions.setLoginDisplayName(response.data.displayName));
      } else {
        dispatch(Auth.actions.setLoginError('login', 'Helytelen e-mail cím vagy jelszó!'));
      }
    } catch (err) {
      dispatch(Auth.actions.setLoginError('login', 'Helytelen e-mail cím vagy jelszó!'));
    }
  };
};

export const doHandleApplicationStarted = () => {
  return async (dispatch: Dispatch) => {
    // dispatch(Auth.actions.setUserLoggedIn());
  };
};