import React from 'react';
import OrderCard from '../../components/cards/order-card';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Common from '../../logic/redux/common';
import Order from '../../logic/redux/order';
import Spinner, { SpinnerModal } from '../../components/loading-indicators/spinner';
import Modal from '../../components/modal/modal';
import {
  DashboardContainer,
  DashboardWaitContainer,
  DashboardInProgressContainer,
  DashboardDoneContainer,
  DashboardHeader,
  DashboardContent,
  DeclineModalButtons,
  DeclineModalContainer,
  DeclineModalMainText,
  DeclineModalInputContainer,
  DetailsModalStatusContainer,
  DetailsModalStatusRow,
  DetailsModalStatusCol,
  DetailsModalDeclineContainer,
  DashboardEmptyText,
  DashboardSpinnerWrapper,
} from './style';
import { gql, useSubscription } from '@apollo/client';
import { orders } from '../../logic/graphql/generated-operations/subscriptions/orders';
import { IStore } from '../../logic/redux/IStore';
import { DateTime } from 'luxon';
import { VariableSizeList } from 'react-window';
import { newOrder } from '../../logic/graphql/generated-operations/subscriptions/newOrder';
import useSound from 'use-sound';
const warnSound = require('../../assets/warning_longer.mp3');

let firstLoad = true;

const Dashboard = (): JSX.Element => {
  if (window) {
    window.addEventListener('click', (e) => {
      setAnimateIncoming(false);
    });
  }

  const loading = useSelector(Common.select.selectLoading);
  const selectedOrders = useSelector(Order.select.selectOrders, shallowEqual);
  const selectOrderDetails = useSelector((state: IStore) => state.order.orderDetail, shallowEqual);
  const [showDeclineModal, setShowDeclineModal] = React.useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState<boolean>(false);
  const [isSpinning, setIsSpinning] = React.useState<boolean>(false);
  const [declineButtonStatus, setDeclineButtonStatus] = React.useState<
    'other' | 'out-of-items' | null
  >(null);
  const [declineText, setDeclineText] = React.useState<string>('');
  const [currentDeclineId, setCurrentDeclineId] = React.useState<number | null>(null);
  const [animateIncoming, setAnimateIncoming] = React.useState<boolean>(false);
  const VariableSizeListIncomingRef = React.useRef<any>(null);
  const VariableSizeListInProgressRef = React.useRef<any>(null);
  const [playSound] = useSound(warnSound);
  const dispatch = useDispatch();

  const ordersSub = useSubscription(
    gql`
      ${orders}
    `,
    { fetchPolicy: 'no-cache' },
  );

  const newOrderSub = useSubscription(
    gql`
      ${newOrder}
    `,
    { fetchPolicy: 'no-cache' },
  );

  React.useEffect(() => {
    if (firstLoad) {
      dispatch(Order.actions.doGetOrders());
      firstLoad = false;
    }
    return () => {
      firstLoad = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (ordersSub.data) {
      dispatch(Order.actions.setOrders(ordersSub.data.orders));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersSub.data]);

  React.useEffect(() => {
    if (newOrderSub.data) {
      setAnimateIncoming(true);
      dispatch(Order.actions.doGetOrders());
      playSound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrderSub.data]);

  React.useEffect(() => {
    if (VariableSizeListIncomingRef.current) {
      VariableSizeListIncomingRef.current.resetAfterIndex(0, true);
    }
  }, [selectedOrders.incoming]);

  React.useEffect(() => {
    if (VariableSizeListInProgressRef.current) {
      VariableSizeListInProgressRef.current.resetAfterIndex(0, true);
    }
  }, [selectedOrders.accepted]);

  const handleStatusChange = (payload: { id: number; status: number; note: string }): void => {
    setAnimateIncoming(false);
    dispatch(Order.actions.doStatusChange(payload));
  };

  const onDeclinePress = (id: number): void => {
    setShowDeclineModal(true);
    setCurrentDeclineId(id);
  };

  const onDeclineOk = (): void => {
    if (currentDeclineId) {
      dispatch(
        Order.actions.doStatusChange({
          id: currentDeclineId,
          status: 3,
          note:
            declineButtonStatus === 'out-of-items'
              ? 'A rendlés termék hiány miatt nem teljesíthető.'
              : declineText,
        }),
      );
      setCurrentDeclineId(null);
      setShowDeclineModal(false);
      if (declineText) {
        setDeclineText('');
      }
    }
  };

  const onDoneCardClick = (id: number): void => {
    dispatch(Order.actions.doGetOrderDetail({ id }));
    setShowDetailsModal(true);
  };

  const onPrintClick = (id: number): void => {
    dispatch(Order.actions.doPrintOrder(id));
    setIsSpinning(true);
    setTimeout(() => {
      setIsSpinning(false);
    }, 2000);
  };

  const renderListElement = (
    listProps: { index: any; style: any },
    type: 'details' | 'done',
    emptyTextType: 'incoming' | 'accepted' | 'done',
  ): JSX.Element => {
    return (
      <OrderCard
        style={listProps.style}
        key={selectedOrders[emptyTextType][listProps.index].id}
        orderData={selectedOrders[emptyTextType][listProps.index]}
        type={type}
        onDoneCardClick={onDoneCardClick}
        onStatusChange={handleStatusChange}
        onShowDeclineModal={onDeclinePress}
        onPrintOrder={onPrintClick}
      />
    );
  };

  const getItemSize = (index: number, emptyTextType: 'incoming' | 'accepted' | 'done'): number => {
    if (emptyTextType !== 'done') {
      const cardSize = 160;
      let cardItemCount = selectedOrders[emptyTextType][index].items.length * 30;
      for (const item of selectedOrders[emptyTextType][index].items) {
        cardItemCount += item.options.length * 30;
      }
      return cardSize + cardItemCount;
    }
    return 75;
  };

  const renderCard = (
    cards: Order.model.IOrder[],
    type: 'details' | 'done',
    emptyTextType: 'incoming' | 'accepted' | 'done',
  ): JSX.Element | null => {
    if (cards.length > 0) {
      return (
        <VariableSizeList
          key={'list' + emptyTextType}
          ref={
            emptyTextType === 'incoming'
              ? VariableSizeListIncomingRef
              : emptyTextType === 'accepted'
              ? VariableSizeListInProgressRef
              : undefined
          }
          width={emptyTextType === 'done' ? 205 : 430}
          height={650}
          style={{ paddingBottom: '15px' }}
          itemCount={selectedOrders[emptyTextType].length}
          itemSize={(index: number) => getItemSize(index, emptyTextType)}
        >
          {(data: any) => renderListElement(data, type, emptyTextType)}
        </VariableSizeList>
      );
    } else {
      if (emptyTextType === 'incoming') {
        return <DashboardEmptyText>Nincs bejövő rendelés</DashboardEmptyText>;
      } else if (emptyTextType === 'accepted') {
        return <DashboardEmptyText>Nincs készülő rendelésed</DashboardEmptyText>;
      } else {
        return <DashboardEmptyText>Nincs lezárt rendelés</DashboardEmptyText>;
      }
    }
  };

  const onDeclineButtonClick = (type: 'out-of-items' | 'other'): void => {
    setDeclineButtonStatus(type);
  };

  const handleDeclineTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDeclineText(event.currentTarget.value);
  };

  const renderDeclineModal = (): JSX.Element => {
    return (
      <DeclineModalContainer>
        <DeclineModalMainText>Miért nem teljesíthető a rendelés?</DeclineModalMainText>
        <DeclineModalButtons
          onClick={() => onDeclineButtonClick('out-of-items')}
          status={
            declineButtonStatus === 'out-of-items'
              ? 'active'
              : declineButtonStatus === 'other'
              ? 'inactive'
              : 'wait'
          }
        >
          A rendelés termék hiány miatt nem teljesíthető.
        </DeclineModalButtons>
        <DeclineModalButtons
          onClick={() => onDeclineButtonClick('other')}
          status={
            declineButtonStatus === 'other'
              ? 'active'
              : declineButtonStatus === 'out-of-items'
              ? 'inactive'
              : 'wait'
          }
        >
          Egyéb ok miatt nem teljesíthető
        </DeclineModalButtons>
        <DeclineModalInputContainer visible={declineButtonStatus === 'other'}>
          <input placeholder="Indok..." onChange={handleDeclineTextChange} />
        </DeclineModalInputContainer>
      </DeclineModalContainer>
    );
  };

  const renderDetailsModal = (): JSX.Element => {
    return (
      <DeclineModalContainer>
        <DetailsModalStatusContainer>
          <DetailsModalStatusRow>
            <DetailsModalStatusCol isMainText={true}>Státusz</DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>Beérkezett</DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>Elfogadva</DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>
              {selectOrderDetails.declined ? 'Elutasítva' : 'Elkészült'}
            </DetailsModalStatusCol>
          </DetailsModalStatusRow>
          <DetailsModalStatusRow>
            <DetailsModalStatusCol isMainText={true}>Idő</DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>
              {DateTime.fromSeconds(selectOrderDetails.createdAt).toFormat('HH:mm')}
            </DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>
              {selectOrderDetails.accepted && selectOrderDetails.accepted.time > 0
                ? DateTime.fromSeconds(selectOrderDetails.accepted.time).toFormat('HH:mm')
                : '-'}
            </DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>
              {DateTime.fromSeconds(
                selectOrderDetails.completed
                  ? selectOrderDetails.completed.time
                  : selectOrderDetails.declined
                  ? selectOrderDetails.declined.time
                  : 0,
              ).toFormat('HH:mm')}
            </DetailsModalStatusCol>
          </DetailsModalStatusRow>
          <DetailsModalStatusRow>
            <DetailsModalStatusCol isMainText={true}>Felhasználó</DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false} />
            <DetailsModalStatusCol isMainText={false}>
              {selectOrderDetails.accepted && selectOrderDetails.accepted.userName
                ? selectOrderDetails.accepted.userName
                : '-'}
            </DetailsModalStatusCol>
            <DetailsModalStatusCol isMainText={false}>
              {selectOrderDetails.completed
                ? selectOrderDetails.completed.userName
                : selectOrderDetails.declined
                ? selectOrderDetails.declined.userName
                : '-'}
            </DetailsModalStatusCol>
          </DetailsModalStatusRow>
          <DetailsModalDeclineContainer show={selectOrderDetails.declined ? true : false}>
            <p>Elutasítás indoka:</p>
            <p>{selectOrderDetails.declined ? selectOrderDetails.declined.note : '-'}</p>
          </DetailsModalDeclineContainer>
        </DetailsModalStatusContainer>
        <OrderCard
          style={undefined}
          orderData={selectOrderDetails}
          type={'details-modal'}
          onDoneCardClick={onDoneCardClick}
          onStatusChange={handleStatusChange}
          onShowDeclineModal={onDeclinePress}
          onPrintOrder={onPrintClick}
        />
      </DeclineModalContainer>
    );
  };

  const onModalCancelPress = (e: any): void => {
    e.stopPropagation();
    setShowDeclineModal(false);
  };

  const onDetailsModalClose = (): void => {
    setShowDetailsModal(false);
    dispatch(Order.actions.initOrderDetails());
  };

  return (
    <DashboardContainer isSpinning={isSpinning || loading > 0}>
      {isSpinning || loading > 0 ? (
        <DashboardSpinnerWrapper>
          <SpinnerModal>
            <Spinner />
          </SpinnerModal>
        </DashboardSpinnerWrapper>
      ) : null}
      <Modal
        showModal={showDeclineModal}
        onOkButton={declineButtonStatus ? onDeclineOk : undefined}
        onCancelButton={onModalCancelPress}
        okButtonText={declineButtonStatus ? 'Elutasítás' : undefined}
        cancelButtonText={'Mégse'}
        headerText={'Elutasítás'}
        mainContent={renderDeclineModal()}
      />
      <Modal
        showModal={showDetailsModal}
        onOkButton={onDetailsModalClose}
        okButtonText={'Bezárás'}
        headerText={'Előzmények #' + selectOrderDetails.displayId}
        mainContent={renderDetailsModal()}
        onCancelButton={onDetailsModalClose}
      />
      <DashboardWaitContainer>
        <DashboardHeader renderBorder={true} blink={animateIncoming}>
          bejövő rendelések ({selectedOrders.incoming.length})
        </DashboardHeader>
        <DashboardContent renderBorder={true}>
          {renderCard(selectedOrders.incoming, 'details', 'incoming')}
        </DashboardContent>
      </DashboardWaitContainer>
      <DashboardInProgressContainer>
        <DashboardHeader renderBorder={true} blink={false}>
          én készítem
        </DashboardHeader>
        <DashboardContent renderBorder={true}>
          {renderCard(selectedOrders.accepted, 'details', 'accepted')}
        </DashboardContent>
      </DashboardInProgressContainer>
      <DashboardDoneContainer>
        <DashboardHeader renderBorder={false} blink={false}>
          lezárt ({selectedOrders.done.length})
        </DashboardHeader>
        <DashboardContent renderBorder={false}>
          {renderCard(selectedOrders.done, 'done', 'done')}
        </DashboardContent>
      </DashboardDoneContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
