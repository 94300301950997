import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Auth from '../../logic/redux/auth';
import {Paths} from '../../navigation/paths';
import { LoginContainer, LoginInnerContainer, LoginInputField, LoginButton, LoginError, EyeCover } from './style';
import {
    EyeOutlined,
    EyeInvisibleOutlined
    } from '@ant-design/icons';

const Login = (): JSX.Element => {

    const dispatch = useDispatch();
    const loggedIn = useSelector(Auth.select.selectLoggedIn);
    const loginErrors = useSelector(Auth.select.selectLoginErrors);
    const redirectPath = useSelector(Auth.select.selectLoginRedirectPath);
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    React.useEffect(() => {
        return () => {
            dispatch(Auth.actions.setLoginRedirectPath(undefined));
        };
    });

    const handleLoginClick = (): void => {
        dispatch(Auth.actions.doLogin(email, password));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.currentTarget.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.currentTarget.value);
    };

    if (loggedIn && redirectPath) {
        return <Redirect to={redirectPath}/>;
    } else if (loggedIn) {
        return <Redirect to={Paths.dashboard}/>;
    }

    const eyeStyle: React.CSSProperties = {
        fontSize: '45px',
        position: 'relative',
        marginLeft: '-80px',
        marginTop: '17px',
        marginRight: '20px',
        float: 'right',
        zIndex: 2,
    };

    const firstLoginStepContent = (
        <>
            <LoginInputField 
                id="email" 
                onChange={handleEmailChange} 
                placeholder="E-mail"
            />
            <p>
                <LoginInputField
                    id="password"
                    onChange={handlePasswordChange}
                    placeholder="Jelszó"
                    type={ showPassword ? "text" : "password"}
                />
                { showPassword ?
                    <EyeInvisibleOutlined onClick={() => {setShowPassword(!showPassword)}} style={eyeStyle} />
                    : <EyeOutlined onClick={() => {setShowPassword(!showPassword)}} style={eyeStyle} />}
                <EyeCover onClick={() => {setShowPassword(!showPassword)}} />
            </p>
            <LoginButton
                onClick={handleLoginClick}
            >
                Bejelentkezés
            </LoginButton>
        </>
    );

    return (
        <>
            <LoginContainer>
                <LoginInnerContainer>
                    {loginErrors.login ?
                        <LoginError>
                            {loginErrors.login}
                        </LoginError> : null
                    }
                    {firstLoginStepContent}
                </LoginInnerContainer>
            </LoginContainer>
        </>
    );
};

export default Login;
