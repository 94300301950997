import styled, { keyframes } from 'styled-components';

export const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
`;

export const NavHeader = styled.nav`
  flex: 0 1 55px;
  justify-content: space-between;
  background-color: #980303;
  align-items: center;
  width: 100%;
  line-height: 55px;
`;

export const NavButton = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 3rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  color: #fff;
  float: left;
`;

export const NavTitleContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NavTitle = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const NavStatus = styled.p`
  display: flex;
  flex-direction: row;
  color: #edf2f7;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px;
`;

const menu_anim_open = keyframes`
    0% {
        transform: translateX(-300px);
    }
    100% {
        transform: translateX(0px); 
    } 
`;

const menu_anim_close = keyframes`
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-300px); 
    } 
`;

interface INavMenuProps {
  visible: 'open' | 'close' | null;
}

export const NavMenu = styled.div<INavMenuProps>`
  position: absolute;
  background-color: #7a0202;
  width: 300px;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  animation: ${(props) => (props.visible === 'open' ? menu_anim_open : menu_anim_close)}
    ${(props) => (props.visible ? '0.5s' : '0s')} ease-out forwards;
`;

export const NavMenuUserSection = styled.section`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const NavMenuUserTitle = styled.p`
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
`;

export const NavMenuUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavMenuUserImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #e0e0e0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const NavMenuUserName = styled.p`
  margin-left: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
`;

interface INavMenuItemProps {
  active: boolean;
}

export const NavMenuItem = styled.div<INavMenuItemProps>`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 66px;
  align-items: center;
  background: ${(props) => (props.active ? 'rgba(255,160,0, 0.12)' : '')};
  color: ${(props) => (props.active ? '#FFA002' : '#fff')};
  & p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 40px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
`;

export const EmptyNavButton = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 3rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  width: 22.5px;
  height: 22.5px;
`;
