import * as React from 'react';
import {UndoOutlined, SearchOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {
    ComplaintButton,
    ComplaintContainer,
    ComplaintFoundWrapper,
    ComplaintInput,
    ComplaintInputTitle,
    ComplaintInputTitleSpan,
    ComplaintPart,
    ComplaintSpinner,
    ResetButton
} from './style';
import Complaint from '../../logic/redux/complaint';
import Common from '../../logic/redux/common';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    DeclineModalContainer,
    DeclineModalMainText, DetailsModalDeclineContainer,
    DetailsModalStatusCol,
    DetailsModalStatusContainer,
    DetailsModalStatusRow
} from "../dashboard/style";
import {DateTime} from "luxon";
import OrderCard from "../../components/cards/order-card";
import Modal from "../../components/modal/modal";
import Spinner, { SpinnerModal } from "../../components/loading-indicators/spinner";

const ComplaintHandler = () => {
    const complaintModal = useSelector(Complaint.select.selectSearchModal, shallowEqual);
    const foundOrders = useSelector(Complaint.select.selectFoundOrders, shallowEqual);
    const displayId = useSelector(Complaint.select.selectDisplayId, shallowEqual);
    const randomId = useSelector(Complaint.select.selectRandomId, shallowEqual);
    const loading = useSelector(Common.select.selectLoading, shallowEqual);
    const dispatch = useDispatch();

    React.useEffect(() => {
    }, [complaintModal, foundOrders, displayId, randomId]);

    const onModalOk = () => {
        dispatch(Complaint.actions.setSearchModal({
            show: false,
            error: false,
            errorText: '',
            id: 0,
        }));
    }

    const onDisplayIdChange = (e: any): void => {
        if (e.target.validity.valid) {
            dispatch(Complaint.actions.setDisplayId(e.target.value));
        }
    }

    const onRandomIdChange = (e: any): void => {
        if (e.target.validity.valid) {
            dispatch(Complaint.actions.setRandomId(e.target.value));
        }
    }

    const onResetDisplayId = () => {
        dispatch(Complaint.actions.setDisplayId(0));
    }

    const onResetRandomId = () => {
        dispatch(Complaint.actions.setRandomId(0));
    }

    const onSearch = () => {
        dispatch(Complaint.actions.doSearch());
    }

    const onResetPage = () => {
        dispatch(Complaint.actions.resetComplaint());
    }

    const onDetailsClick = (index: number) => {
        dispatch(Complaint.actions.setSearchModal({
            show: true,
            errorText: '',
            error: false,
            id: index,
        }))
    }

    const renderFound = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        for (let i = 0; i < foundOrders.length; i++) {
            const found = foundOrders[i];
            elements.push(
                <OrderCard
                    style={undefined}
                    key={found.id}
                    orderData={found}
                    type={'done'}
                    onDoneCardClick={() => { onDetailsClick(i) }}
                    onStatusChange={() => {}}
                    onShowDeclineModal={() => {}}
                    onPrintOrder={() => {}}
                />
            );
        }
        return elements;
    }

    const renderErrorModal = (): JSX.Element => {
        return (
            <DeclineModalContainer>
                <DeclineModalMainText>
                    {complaintModal.errorText}
                </DeclineModalMainText>
            </DeclineModalContainer>
        )
    }

    const renderModal = (): JSX.Element | null => {
        if (complaintModal.error && complaintModal.show) {
            return renderErrorModal();
        } else if (!complaintModal.error && complaintModal.show) {
            return renderDetailsModal();
        } else {
            return null;
        }
    }

    const renderDetailsModal = (): JSX.Element => {
        return (
            <DeclineModalContainer>
                <DetailsModalStatusContainer>
                    <DetailsModalStatusRow>
                        <DetailsModalStatusCol isMainText={true}>
                            Státusz
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            Beérkezett
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            Elfogadva
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            {foundOrders[complaintModal.id].declined ? 'Elutasítva' : 'Elkészült'}
                        </DetailsModalStatusCol>
                    </DetailsModalStatusRow>
                    <DetailsModalStatusRow>
                        <DetailsModalStatusCol isMainText={true}>
                            Idő
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            {foundOrders[complaintModal.id] ? DateTime.fromSeconds(foundOrders[complaintModal.id].createdAt).toFormat('HH:mm') : ''}
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            {foundOrders[complaintModal.id] && foundOrders[complaintModal.id]?.accepted && foundOrders[complaintModal.id]?.accepted.time > 0 ? DateTime.fromSeconds(foundOrders[complaintModal.id]?.accepted.time).toFormat('HH:mm') : '-'}
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            {foundOrders[complaintModal.id] && foundOrders[complaintModal.id].declined ?
                                DateTime.fromSeconds(foundOrders[complaintModal.id]?.declined ? foundOrders[complaintModal.id]?.declined.time : 0).toFormat('HH:mm')
                                : DateTime.fromSeconds(foundOrders[complaintModal.id]?.completed ? foundOrders[complaintModal.id]?.completed.time : 0).toFormat('HH:mm')}
                        </DetailsModalStatusCol>
                    </DetailsModalStatusRow>
                    <DetailsModalStatusRow>
                        <DetailsModalStatusCol isMainText={true}>
                            Felhasználó
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false} />
                        <DetailsModalStatusCol isMainText={false}>
                            {foundOrders[complaintModal.id].accepted && foundOrders[complaintModal.id].accepted?.userName ? foundOrders[complaintModal.id].accepted?.userName : '-'}
                        </DetailsModalStatusCol>
                        <DetailsModalStatusCol isMainText={false}>
                            {foundOrders[complaintModal.id].completed ? foundOrders[complaintModal.id].completed?.userName : foundOrders[complaintModal.id].declined ? foundOrders[complaintModal.id].declined?.userName : '-'}
                        </DetailsModalStatusCol>
                    </DetailsModalStatusRow>
                    <DetailsModalDeclineContainer show={foundOrders[complaintModal.id].declined ? true : false}>
                        <p>
                            Elutasítás indoka:
                        </p>
                        <p>
                            {foundOrders[complaintModal.id].declined ? foundOrders[complaintModal.id]?.declined?.note : '-'}
                        </p>
                    </DetailsModalDeclineContainer>
                </DetailsModalStatusContainer>
                <OrderCard
                    style={undefined}
                    orderData={foundOrders[complaintModal.id]}
                    type={'details-modal'}
                    onDoneCardClick={() => null}
                    onStatusChange={() => null}
                    onShowDeclineModal={() => null}
                    onPrintOrder={() => null}
                />
            </DeclineModalContainer>
        )
    }

    const resetIconStyle: React.CSSProperties = {
        fontSize: '20px',
        position: 'relative',
        marginLeft: '-25px',
        marginTop: '10px',
        marginRight: '50px',
        float: 'right',
        zIndex: 2,
    };

    return (
        <ComplaintContainer isSpinning={loading > 0}>
            {loading ?
                <ComplaintSpinner>
                    <SpinnerModal>
                        <Spinner />
                    </SpinnerModal>
                </ComplaintSpinner>
            : null}
            <ComplaintPart>
                <ComplaintInputTitle>
                    <ComplaintInputTitleSpan>
                        Rendelés egyedi azonosítója
                    </ComplaintInputTitleSpan>
                    <ComplaintInput
                        type={'text'}
                        onChange={onRandomIdChange}
                        value={randomId ? randomId : ''}
                        pattern="\d*"
                    />
                    <CloseCircleOutlined onClick={onResetRandomId} style={resetIconStyle}/>
                    <ResetButton onClick={onResetRandomId}/>
                </ComplaintInputTitle>
                <ComplaintInputTitle>
                    <ComplaintInputTitleSpan>
                        Rendelés száma
                    </ComplaintInputTitleSpan>
                    <ComplaintInput
                        type={'text'}
                        onChange={onDisplayIdChange}
                        value={displayId ? displayId : ''}
                        pattern="\d*"
                    />
                    <CloseCircleOutlined onClick={onResetDisplayId} style={resetIconStyle}/>
                    <ResetButton onClick={onResetDisplayId}/>
                </ComplaintInputTitle>
            </ComplaintPart>
            <ComplaintPart>
                <ComplaintButton
                    active={true}
                    onClick={onSearch}
                >
                    <p>Keresés <SearchOutlined/></p>
                </ComplaintButton>
            </ComplaintPart>
            <ComplaintPart>
                <ComplaintButton
                    active={true}
                    onClick={onResetPage}
                >
                    <p>Alaphelyzet <UndoOutlined/></p>
                </ComplaintButton>
            </ComplaintPart>
            <ComplaintFoundWrapper>
                {renderFound()}
            </ComplaintFoundWrapper>
            <Modal
                showModal={complaintModal.show}
                onOkButton={onModalOk}
                onCancelButton={onModalOk}
                okButtonText={'OK'}
                headerText={complaintModal.error ? 'Hiba' : 'A rendelés részletei'}
                mainContent={renderModal()}/>
        </ComplaintContainer>
    );
}

export default ComplaintHandler;