import styled, {keyframes, css} from 'styled-components';

interface IDashboardContainerProps {
    isSpinning: boolean;
}

export const DashboardContainer = styled.div<IDashboardContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: ${props => props.isSpinning ? 'rgba(0 ,0 ,0, 0.2)' : ''};
`;

export const DashboardWaitContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;

interface IDashboardHeaderProps {
    renderBorder: boolean;
    blink: boolean;
}

export const DashboardHeader = styled.div<IDashboardHeaderProps>`
    display: flex;
    flex: 0 1 60px;
    justify-content: center;
    background-color: #980303;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
    border-right: ${props => props.renderBorder ? '1px solid #F7AD33' : ''};
    & div {
        display: block;
    };
    animation: ${props => props.blink ? animationRule : ''};
`;

interface IDashboardContentProps {
    renderBorder: boolean;
}

export const DashboardContent = styled.div<IDashboardContentProps>`
    flex: 1 1 auto;
    height: auto;
    display: flex;
    justify-content: center;
    max-height: 650px;
    overflow-y: scroll;
    border-right: ${props => props.renderBorder ? '1px solid #7E0000' : ''};
`;

export const DashboardInProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;

export const DashboardDoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
`;

export const DashboardEmptyText = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #7A0202;
    text-align: center;
`;

export const DeclineModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const DeclineModalMainText = styled.div`
    padding-left: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #7A0202;
`;

interface IDeclineModalButtonsProps {
    status: 'active' | 'inactive' | 'wait';
}

export const DeclineModalButtons = styled.div<IDeclineModalButtonsProps>`
    display: flex;
    margin-bottom: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #7A0202;
    background: ${props => props.status === 'wait' ? 'rgba(247, 173, 51, 0.6)' : props.status === 'active' ? '#FFA002' : '#BDBDBD'};
    width: 100%;
`;

interface IDeclineModalInputContainerProps {
    visible: boolean;
}

export const DeclineModalInputContainer = styled.div<IDeclineModalInputContainerProps>`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const DetailsModalStatusContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
`;

export const DetailsModalStatusRow = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

interface IDetailsModalStatusColProps {
    isMainText: boolean;
}

export const DetailsModalStatusCol = styled.div<IDetailsModalStatusColProps>`
    width: 25%;
    font-size: 18px;
    font-weight: ${props => props.isMainText ? '700' : '400'};
    color: #7A0202;
    word-wrap: break-word;
`;

interface IDetailsModalDeclineProps {
    show: boolean;
}

export const DetailsModalDeclineContainer = styled.div<IDetailsModalDeclineProps>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: row;
    width: 100%;
    color: #7A0202;
    & p:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
        white-space: nowrap;
    };
    & p:nth-child(2) {
        padding-left: 10px;
        font-size: 18px;
        font-weight: 400;
        word-wrap: break-word;
    }
`;

const blink_background = keyframes`
    0%, 49% {
        background-color: rgb(117, 209, 63);
    }
    50%, 100% {
        background-color: #e50000;
    }
`;

const animationRule = css`
  ${blink_background} 1s infinite alternate;
`

export const DashboardSpinnerWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 50%;
`;