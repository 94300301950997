export const searchOrders = `query searchOrders($input: SearchOrders!){
    searchOrders(input: $input){
        id
        displayId
        randomId
        status
        price
        createdAt
        foodOrderTime
        items{
            name
            price
            quantity
            id
            options{
                name
                price
                quantity
                id
            }
        }
        accepted{
            time
            userName
            note
        }
        declined{
            time
            userName
            note
        }
        completed{
            time
            userName
            note
        }
    }
}`;
