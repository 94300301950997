import axios from 'axios';

const baseURL =
  process.env.REACT_APP_API !== undefined
    ? process.env.REACT_APP_API
    : 'http://' + window.location.hostname + ':8010';
//const baseURL = 'http://localhost:8010';

async function apiHandler(type: 'post' | 'get', uri: string, data?: any, timeout = 25000) {
  const axiosInstance = axios.create({
    baseURL,
    timeout,
    withCredentials: true,
  });

  const unAuthConfig = {
    headers: {
      Accept: 'application/json',
    },
    withCredentials: true,
  };

  const config = unAuthConfig;

  let apiResponse: any;
  if (data) {
    try {
      if (type === 'post') {
        apiResponse = await axiosInstance.post('/' + uri, data, config);
      } else {
        apiResponse = await axiosInstance.get('/' + uri, config);
      }
      if (apiResponse) {
        // valid api response to return and handle properly in the saga
        return apiResponse;
      } else {
        // no valid api response to return
        return false;
      }
    } catch (e) {
      if (e.response) {
        // have response from the backend with some error status code. It is handled properly in the saga.
        return e.response;
      }
      // no valid api response to return
      return false;
    }
  }
}

export async function callLogin(data: { email: string; password: string }) {
  return await apiHandler('post', 'login', data, 0);
}

export async function callPing() {
  return await apiHandler('get', 'ping', 'empty', 0);
}
