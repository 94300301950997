import {Dispatch} from 'redux';
import Common from '../common';
import {IStore} from '../IStore';
import client from "../../graphql/graphql-apollo";
import Order from '.';
import { orders } from '../../graphql/generated-operations/queries/orders';
import { gql } from '@apollo/client';
import { statusChange } from '../../graphql/generated-operations/mutations/statusChange';
import { orderDetails } from '../../graphql/generated-operations/queries/orderDetails';
import {printOrder} from '../../graphql/generated-operations/mutations/printOrder';

export const doGetOrders = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(Common.actions.increaseLoading());
        try {
            const result = await client.query({
                query: gql`${orders}`,
                fetchPolicy: "no-cache",
            });
            console.log(result);
            if (!result.error) {
                dispatch(Order.actions.setOrders(result.data.orders));
            }
        } catch (err) {
            // TODO: Error handling
        }
        dispatch(Common.actions.decreaseLoading());
    };
};

export const doStatusChange = (payload: {id: number, status: number, note: string}) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        try {
            const result = await client.mutate({
                mutation:  gql`${statusChange}`,
                variables: {"input": {"id": payload.id, "status": payload.status, "note": payload.note}},
            });
            console.log(result);
        } catch (err) {
            // TODO: Error handling
        }
    };
};

export const doGetOrderDetail = (payload: {id: number}) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(Common.actions.increaseLoading());
        try {
            const result = await client.query({
                query: gql`${orderDetails}`,
                variables: {"input": payload.id},
                fetchPolicy: "no-cache",
            });
            console.log(result);
            if (!result.error) {
                dispatch(Order.actions.setOrderDetails(result.data.orderDetails));
            }
        } catch (err) {
            // TODO: Error handling
        }
        dispatch(Common.actions.decreaseLoading());
    };
};

export const doPrintOrder = (payload: number) => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(Common.actions.increaseLoading());
        try {
            const result = await client.mutate({
                mutation: gql`${printOrder}`,
                variables: {"input": payload},
                fetchPolicy: "no-cache",
            });
            console.log(result);
        } catch (err) {
            // TODO: Error handling
        }
        dispatch(Common.actions.decreaseLoading());
    }
}