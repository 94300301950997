import styled from 'styled-components';

interface IModalContainerProps {
    showModal: boolean;
}

export const ModalContainer = styled.div<IModalContainerProps>`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
    display: ${props => props.showModal ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ModalInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 4px 4px;
    z-index: 2;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex: 0 1 50px;
    flex-direction: column;
    justify-content: center;
    background-color: #FFA002;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    & p {
        padding-left: 10px;
        margin-bottom: 0;
        margin-top: 0;
    };
`;

export const ModalContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    width: 100%;
`;

export const ModalContentInner = styled.div`
    width: 100%;
`;

export const ModalFooter = styled.div`
    display: flex;
    width: 100%;
    flex: 0 1 50px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ModalFooterButton = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    color: #009688;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
`;