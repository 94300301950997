import produce from 'immer';
import {ComplaintReducer, SearchModal} from "./complaint-models";
import Complaint from '.'
import {Order} from "../../graphql/types/types";

const initializeState: ComplaintReducer = {
    foundOrders: [],
    randomId: 0,
    displayId: 0,
    searchModal: {
        show: false,
        id: 0,
        error: false,
        errorText: '',
    }
};

export default (
    state = initializeState,
    action: { type: string; payload: Complaint.model.ComplaintActionsPayload },
) =>
    produce(state, (draft) => {
        switch (action.type) {
            case Complaint.actionTypes.SET_FOUND_ORDERS:
                draft.foundOrders = action.payload as Order[];
                return draft;
            case Complaint.actionTypes.SET_DISPLAY_ID:
                draft.displayId = action.payload as number;
                return draft;
            case Complaint.actionTypes.SET_RANDOM_ID:
                draft.randomId = action.payload as number;
                return draft;
            case Complaint.actionTypes.RESET_COMPLAINT:
                draft = initializeState;
                return draft;
            case Complaint.actionTypes.SET_SEARCH_MODAL:
                draft.searchModal = action.payload as SearchModal;
                return draft;
            default:
        }
    });