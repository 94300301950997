import {Dispatch} from "redux";
import {IStore} from "../IStore";
import Common from '.'
import {callPing} from "../../api/api";

export const doCheckOnline = () => {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        try {
            const response = await callPing();
            if (response.status === 200) {
                dispatch(Common.actions.setOnline(true));
            } else {
                dispatch(Common.actions.setOnline(false));
            }
        } catch (e) {
            console.error(e);
            dispatch(Common.actions.setOnline(false));
        }
    }
}