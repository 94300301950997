/* eslint-disable @typescript-eslint/explicit-function-return-type */
import produce from 'immer';
import Export from '.';
import {ExportErrorModal, ExportReducer} from './export-models';
import {ExportQuery} from '../../graphql/types/types';

const initializeState: ExportReducer = {
    From: 0,
    To: 0,
    LastExport: null,
    ExportErrorModal: {
        show: false,
        text: '',
    }
}

export default (
    state = initializeState,
    action: { type: string; payload: Export.model.ExportActionsPayload },
) =>
    produce(state, (draft) => {
        switch (action.type) {
            case Export.actionTypes.SET_LAST_EXPORT:
                draft.LastExport = action.payload as ExportQuery;
                return draft;
            case Export.actionTypes.EMPTY_EXPORT:
                draft = initializeState;
                return draft;
            case Export.actionTypes.SET_FROM:
                draft.From = action.payload as number;
                return draft;
            case Export.actionTypes.SET_TO:
                draft.To = action.payload as number;
                return draft;
            case Export.actionTypes.SET_ERROR_MODAL:
                draft.ExportErrorModal = action.payload as ExportErrorModal;
                return draft;
            default:
        }
    });
