import styled from 'styled-components';

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

export const LoginInnerContainer = styled.div`
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
`;

export const LoginInputField = styled.input`
    width: 681px;
    height: 76px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 500;
    background: #F6F6F6;
    border: 1px solid #E8E8E8;
    margin-bottom: 28px;
    padding-left: 10px;
`;

export const LoginButton = styled.div`
    width: 681px;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #980303;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
`;

export const LoginError = styled.div`
    font-size: 20px;
    color: #980303;
    margin-bottom: 28px;
    padding-left: 10px;
`;

export const EyeCover = styled.div`
    position: relative;
    margin-left: -80px;
    float: right;
    z-index: 3;
    width: 80px;
    height: 80px;
`;