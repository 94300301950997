import React from 'react';
import { MenuEditorContainer } from './style';

const MenuEditor = (): JSX.Element => {

    return (
        <MenuEditorContainer>
            menuEditor content
        </MenuEditorContainer>
    );
};

export default MenuEditor;
