import { action } from 'typesafe-actions';
import {Order} from "../../graphql/types/types";
import {SearchModal} from "./complaint-models";
import {doSearch} from "./complaint-thunk-actions";

export const actionTypes = {
    SET_FOUND_ORDERS: 'complaint/SetFoundOrder',
    SET_DISPLAY_ID: 'complaint/SetDisplayId',
    SET_RANDOM_ID: 'complaint/SetRandomId',
    RESET_COMPLAINT: 'complaint/Reset',
    SET_SEARCH_MODAL: 'complaint/SetSearchModal',
};

export const actions = {
    setFoundOrders: (payload: Order[]) => action(actionTypes.SET_FOUND_ORDERS, payload),
    setDisplayId: (payload: number) => action(actionTypes.SET_DISPLAY_ID, payload),
    setRandomId: (payload: number) => action(actionTypes.SET_RANDOM_ID, payload),
    resetComplaint: () => action(actionTypes.RESET_COMPLAINT),
    setSearchModal: (payload: SearchModal) => action(actionTypes.SET_SEARCH_MODAL, payload),
    doSearch,
};