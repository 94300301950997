import styled from 'styled-components';

export const SwipeButtonContainer = styled.div`
    border: 2px solid #009688;
    border-radius: 5px;
    min-width: 150px;
    padding: 5px;
    position: relative;
    overflow: hidden;
    color: #009688;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    user-select: none;
`;

export const SwipeButtonOverlay = styled.div`
    position: absolute;
    left: -5px;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 2rem;
    background: #009688;
    transition: width 0.2s ease-out;
    will-change: width;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SwipeButtonOverlayWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const SwipeButtonCaretWrapper = styled.div`
    position: absolute;
    max-width: 45px;
    width: 20px;
    min-width: 0;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    background: #009688;
    z-index: 2;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    justify-content: center;
`;

export const SwipeButtonOverlayText = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
`;