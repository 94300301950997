const dashboard = '/';
const login = '/login';
const exportScreen = '/export';
const diagnostic = '/diagnostic';
const menuEditor = '/menu_editor';
const complaint = '/complaint';

export const Paths = {
  login,
  dashboard,
  exportScreen,
  diagnostic,
  menuEditor,
  complaint
};
